.clock {
    font-size: 30px;
    /* background-color: rgba(158, 255, 158, 0.719); */
    /* padding-top: 5px; */
    display: flex;
    justify-content: center;
  }
.clockDisplay {
  background: linear-gradient(to bottom, rgb(224, 224, 224), rgb(182, 182, 182), rgba(176, 176, 176, 0.627));
  width:fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#menuBtn {
  position: fixed;
  top: 3px;
  left: 8px;
  font-size: 50px;
}
.questionCenter {
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.questionCenter div{
  display:flex;
  flex-direction: column;
  
}
.innerQCenter {
  min-width: 400px;
  max-width: 500px;
}
#popBg {
  background-color: rgba(183, 183, 183, 0.744);
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#popDoing {
  background-color: rgba(37, 37, 37, 0.774);
  min-width: 500px;
  min-height: 220px;
  border-radius: 30px;
}

.align-btn button {
  width: 200px;
  position:absolute;
}
.align-btn button:last-child {
  transform: translateX(300px);
}

#cancelIcon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 50px;
}

.dones {
  background-color:aliceblue;
  color: grey;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.ResponsiveContainer {
  text-align: center;
}